const readApiBaseUrl = async () => {
  try {
    const response = await fetch('/apiBaseUrl.txt');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.text();
    return data.trim();
  } catch (err) {
    console.error("Error fetching apiBaseUrl.txt file:", err);
    throw err;
  }
};

const apiModule = {
  apiBaseUrl: '',
  endpoints: {
    // 能耗相关接口
    totalEnergyConsumption: "energyQuery/getTotalEnergyConsumption", // 获取总能耗
    TodayEnergyConsumption: "energyQuery/getEnergyConsumptionBetween", // 获取今日能耗
    getEnergyConsumptionDay: "energyQuery/getEnergyConsumptionDay", // 当日逐时图表数据
    getEnergyConsumptionMonth: "energyQuery/getEnergyConsumptionMonth", // 当月逐日
    getEnergyConsumptionYear: "energyQuery/getEnergyConsumptionYear", // 当年逐月
    getEnergyRanking: "energyQuery/getEnergyRanking", // 用能排行

    // 区域相关接口
    getAlllocation: "location/getAll", // 查询所有区域
    getDepartmentsByParentId: "location/getParentId", // 根据parentId获取分组
    updateDepartment: "location/update", // 更新分组
    deleteDepartment: "location/delete", // 删除分组
    newDepartment: "location/insert", // 新增分组
    insertLocationBatch: "location/insertBatch", // 批量导入区域
    getById: "location/getById", // 根据区域查询全部设备

    // 设备相关接口
    getAllequipment: "equipment/getAllEquipment", // 查询全部设备
    getAllNoequipment: "equipment/get", // 获取所有未绑定的设备
    insertequipment: "locationEquipment/insert", // 新增分组设备绑定
    deleteequipment: "locationEquipment/delete", // 解除分组设备绑定
    deleteBatch: "locationEquipment/deleteBatch", // 批量解除区域设备绑定
    insertList: "locationEquipment/insertList", // 批量绑定分组设备

    // 能耗报表相关接口
    getEnergyReportForms: "energyReportForms/getEnergyReportForms", // 能耗报表查询
    getEnergyReportEquipmentForms: "energyReportForms/getEnergyReportEquipmentForms", // 能耗报表按设备查询
    getEnergyReportFormsExcel: "energyReportForms/getEnergyReportFormsExcel", // 能耗报表导出
    getEnergyEquipmentReportFormsExcel: "energyReportForms/getEnergyReportEquipmentFormsExcel", // 能耗设备报表导出

    // 集中复制相关接口
    getCentralizedCopying: "centralizedCopying/getCentralizedCopying", // 根据分组集合查询用能
    getCentralizedCopyingExcel: "centralizedCopying/getCentralizedCopyingExcel", // 用能集合导出
    getCentralizedCopyingEquipment: "centralizedCopying/getCentralizedCopyingEquipment", // 根据设备集合查询用能
    getCentralizedCopyingEquipmentExcel: "centralizedCopying/getCentralizedCopyingEquipmentExcel", // 根据设备集合导出用能

    // 年同比相关接口
    getYearYoyQuery: "yearYoyQuery/getYearYoyQuery", // 年同比查询
    getYearYoyQueryExcel: "yearYoyQuery/getYearYoyQueryExcel", // 年同比导出
    getEquipmentYearYoyQuery: "yearYoyQuery/getEquipmentYearYoyQuery", // 设备年同比查询
    getEquipmentYearYoyQueryExcel: "yearYoyQuery/getEquipmentYearYoyQueryExcel", // 设备年同比导出

    // 月环比相关接口
    getMonthOnMonth: "monthOnMonthQuery/getMonthOnMonth", // 环比查询
    getMonthOnMonthExcel: "monthOnMonthQuery/getMonthOnMonthExcel", // 环比查询导出
    getEquipmentMonthOnMonth: "monthOnMonthQuery/getEquipmentMonthOnMonth", // 根据设备环比查询
    getEquipmentMonthOnMonthExcel: "monthOnMonthQuery/getEquipmentMonthOnMonthExcel", // 根据设备环比查询导出

    // 策略管理相关接口
    getAllLocation: "strategy/getAll", // 分组绑定策略
    getCustomRegionStrategy: "strategy/customRegionStrategy", // 分组绑定策略
    queryCustomRegionStrategy: "strategy/queryCustomRegionStrategy", // 查询策略绑定的分组id集合
    deleteRule: "strategy/deleteRule", // 删除策略规则
    deleteStrategy: "strategy/deleteStrategy", // 删除策略
    insertRule: "strategy/insertRule", // 新增策略规则
    insertStrategy: "strategy/insertStrategy", // 新增策略
    strategyAll: "strategy/strategyAll", // 查询全部策略
    strategyRule: "strategy/strategyRule", // 查询对应策略的规则
    updateRule: "strategy/updateRule", // 修改策略规则
    updateStrategy: "strategy/updateStrategy", // 更新策略


    //峰谷平查询
    queryCustomRegionPeakValleyLevel: "peakValleyLevel/queryCustomRegionPeakValleyLevel",//分时段查询(分组)
    queryEquipmentPeakValleyLevel: "peakValleyLevel/queryEquipmentPeakValleyLevel",//分时段查询(设备)
    rerunPeakValleyLevel: "peakValleyLevel/rerunPeakValleyLevel",//峰谷平策略重写
    //峰谷平原始记录
    queryStrategyPeakValleyLevelVariableEnergyHour: "strategy-peak-valley/getById",//根据策略ID设备时间(yyyy-MM-dd)获取记录
    updateStrategyPeakValleyLevelVariableEnergyHour: "strategy-peak-valley/update",//更新记录
    //固定查询
    getAllFixedCustomRegion: "fixed/getAllFixedCustomRegion",//查询全部绑定了固定策略的分组
    getAllEquipmentFixed: "fixed/getAllEquipmentFixed",//查询全部绑定了固定策略的分组设备
    queryCustomRegionFixed: "fixed/queryCustomRegionFixed",//固定查询(分组)
    queryEquipmentFixed: "fixed/queryEquipmentFixed",//固定查询(设备)
  },
  async initialize() {
    const data = await readApiBaseUrl();
    const lines = data.split('\n');
    const apiBaseUrlLine = lines.find(line => line.startsWith('ServeUrl='));
    const projectIdLine = lines.find(line => line.startsWith('projectId='));
    if (apiBaseUrlLine) {this.apiBaseUrl = apiBaseUrlLine.split('=')[1].replace(/[";]/g, '').trim();}
    this.apiBaseUrl = "http://"+window.location.hostname+":"+window.location.port+"/api/"//自适应ip端口
    if (projectIdLine) {this.projectId = projectIdLine.split('=')[1].replace(/[";]/g, '').trim();}
  },
};
(async () => {
  await apiModule.initialize();
  // console.log(apiModule.apiBaseUrl);
})();

export default apiModule;
