<!-- /*
                   _ooOoo_
                  o8888888o
                  88" . "88
                  (| -_- |)
                  O\  =  /O
               ____/`---'\____
             .'  \\|     |//  `.
            /  \\|||  :  |||//  \
           /  _||||| -:- |||||-  \
           |   | \\\  -  /// |   |
           | \_|  ''\---/''  |   |
           \  .-\__  `-`  ___/-. /
         ___`. .'  /--.--\  `. . __
      ."" '<  `.___\_<|>_/___.'  >'"".
     | | :  `- \`.;`\ _ /`;.`/ - ` : | |
     \  \ `-.   \_ __\ /__ _/   .-` /  /
======`-.____`-.___\_____/___.-`____.-'======
                   `=---='
^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
         佛祖保佑       永无BUG
*/ -->
<template>
  <el-watermark :font="font" :content="watermarkContent">
    <el-row type="flex" justify="end">
      <el-col :span="24">
        <el-button :type="electricButtonType" @click="toggleButtonType('electric')">电</el-button>
        <el-button :type="waterButtonType" @click="toggleButtonType('water')">水</el-button>
      </el-col>
    </el-row>
    <div class="app-container">
      <el-row :gutter="20" v-loading="loading">
        <el-col :span="5">
          <div class="usage-card">
            <div class="percentage">
              <img v-if="toggleButtonTypes === 'electricity'" src="@/assets/svg/electricity.svg" class="icon" width="20"
                   height="20"/>
              <img v-else-if="toggleButtonTypes === 'water'" src="@/assets/svg/water.svg" class="icon" width="16"
                   height="16"/>
            </div>
            <div class="main-usage">
              <div><b>当前总能耗</b></div>
              <div style="padding-top: 22px;">
                <span class="value">{{ totalEnergyConsumption.toFixed(2) }}</span>
                <span class="unit">{{ unit }}</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <UsageCard
              :percentage="comparisonYesterdayText"
              :percentage-color="isUpward(comparisonYesterdayText)"
              :main-usage-title="'今日用量'"
              :main-usage-value="todayUsage.toFixed(2)"
              :last-day-usage="yesterdayUsage.toFixed(2)"
              :unit="unit"
              :toggle-button-types="toggleButtonTypes"
          />
        </el-col>
        <el-col :span="5">
          <UsageCard
              :percentage="comparisonWeekText"
              :percentage-color="isUpward(comparisonWeekText)"
              :main-usage-title="'本周用量'"
              :main-usage-value="WeekUsage.toFixed(2)"
              :last-day-usage="LastWeekUsage.toFixed(2)"
              :unit="unit"
              :toggle-button-types="toggleButtonTypes"
          />
        </el-col>
        <el-col :span="5">
          <UsageCard
              :percentage="comparisonMonthText"
              :percentage-color="isUpward(comparisonMonthText)"
              :main-usage-title="'本月用量'"
              :main-usage-value="MonthUsage.toFixed(2)"
              :last-day-usage="MonthsUsage.toFixed(2)"
              :unit="unit"
              :toggle-button-types="toggleButtonTypes"
          />
        </el-col>
        <el-col :span="5">
          <UsageCard
              :percentage="comparisonYearText"
              :percentage-color="isUpward(comparisonYearText)"
              :main-usage-title="'当年用量'"
              :main-usage-value="YearUsage.toFixed(2)"
              :last-day-usage="LastYearUsage.toFixed(2)"
              :unit="unit"
              :toggle-button-types="toggleButtonTypes"
          />
        </el-col>
      </el-row>
      <el-row :gutter="24" style="margin-top: 20px;">
        <ChartCard :title="chartTitles.daily" :chart-option="dailyChartOption" :chart-ref="chartRef"
                   :initialMarkPointsVisible="false"/>
        <ChartCard :title="chartTitles.monthly" :chart-option="monthlyChartOption" :chart-ref="chartRefa"
                   :initialMarkPointsVisible="false"/>
        <ChartCard :title="chartTitles.yearly" :chart-option="yearChartOption" :chart-ref="chartRefb"
                   :initialMarkPointsVisible="false"/>
        <RankingCard
            :title="'用能排行'"
            :time-frame="timeFrame"
            :date-range="dateRange"
            :month="month"
            :year="year"
            :ranking-option="Ranking"
            :fetch-data="fetchRankingData"
            :toggle-button-types="toggleButtonTypes"
        />
      </el-row>
    </div>
  </el-watermark>
</template>

<script>
import {ref, onMounted, nextTick, watch} from 'vue';
import axios from 'axios';
import {use} from 'echarts/core';
import {CanvasRenderer} from 'echarts/renderers';
import {BarChart, LineChart} from 'echarts/charts';
import {TitleComponent, TooltipComponent, GridComponent, LegendComponent, MarkPointComponent} from 'echarts/components';
import ApiConfig from '@/APIConfig.js'; // API接口配置文件
import * as Config from '@/Config.js'; // 相关配置文件

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent
]);

import UsageCard from './UsageCard.vue';
import ChartCard from './ChartCard.vue';
import RankingCard from './RankingCard.vue';

export default {
  name: 'OverviewView',
  components: {UsageCard, ChartCard, RankingCard},
  data() {
    return {
      watermarkContent: ['GREATGROUP.COM'], // 水印内容
      font: {
        color: 'rgba(0, 0, 0, .08)', // 设置字体颜色为半透明的红色
      },
      totalEnergyConsumption: 0, // 总能耗
      comparisonYesterdayText: 0, // 与昨天的对比文本
      todayUsage: 0, // 今天的用量
      yesterdayUsage: 0, // 昨天的用量
      comparisonWeekText: 0, // 与上周的对比文本
      WeekUsage: 0, // 本周的用量
      LastWeekUsage: 0, // 上周的用量
      comparisonMonthText: 0, // 与上月的对比文本
      MonthUsage: 0, // 本月的用量
      MonthsUsage: 0, // 上月的用量
      comparisonYearText: 0, // 与去年的对比文本
      YearUsage: 0, // 今年的用量
      LastYearUsage: 0, // 去年的用量
      timeFrame: 'day', // 时间框架（day, month, year）
      dateRange: [], // 日期范围
      month: '', // 当前月份
      year: '', // 当前年份
      electricButtonType: 'primary', // 电按钮类型
      waterButtonType: '', // 水按钮类型
      toggleButtonTypes: 'electricity', // 初始为电类型
      loading: ref(true), // 加载状态
      markPointsVisible: true, // 是否显示标记点
      markPointButtonType: 'primary', // 标记点按钮类型
      unit: 'kWh',
      chartTitles: {
        daily: '当日逐时用电量',
        monthly: '当月逐日用电量',
        yearly: '当年逐月用电量'
      },
      dailyChartOption: {
        tooltip: {
          trigger: 'axis',
          formatter: params => {
            let result = `${params[0].axisValue}<br/>`;
            params.forEach(item => {
              result += `${item.seriesName}：${item.data} ${this.unit}<br/>`;
            });
            return result;
          }
        },
        legend: {data: ['昨日', '今日']},
        xAxis: {type: 'category', data: Array.from({length: 24}, (_, i) => `${i}点`)},
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: value => `${value} ${this.unit}`
          }
        },
        series: [
          {
            name: '昨日',
            type: 'line',
            smooth: true,
            areaStyle: {},
            data: [],
            markPoint: {
              data: [
                {type: 'min', name: '最小值'},
                {type: 'max', name: '最大值'},
                {type: 'average', name: '平均值'}
              ],
              label: {show: true, formatter: '{b}: {c}'}
            }
          },
          {
            name: '今日',
            type: 'line',
            smooth: true,
            areaStyle: {},
            data: [],
            markPoint: {
              data: [
                {type: 'min', name: '最小值'},
                {type: 'max', name: '最大值'},
                {type: 'average', name: '平均值'}
              ],
              label: {show: true, formatter: '{b}: {c}'}
            }
          }
        ],
      },
      monthlyChartOption: {
        tooltip: {
          trigger: 'axis',
          formatter: params => {
            let result = `${params[0].axisValueLabel}<br/>`;
            params.forEach(item => {
              result += `${item.seriesName}：${item.data} ${this.unit}<br/>`;
            });
            return result;
          }
        },
        legend: {data: ['当月', '上月']},
        xAxis: {type: 'category', data: Array.from({length: 31}, (_, i) => `${i + 1}号`)},
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: value => `${value} ${this.unit}`
          }
        },
        series: [
          {
            name: '当月',
            type: 'bar',
            smooth: true,
            data: [],
          },
          {
            name: '上月',
            type: 'bar',
            smooth: true,
            data: [],
          },
        ],
      },
      yearChartOption: {
        tooltip: {
          trigger: 'axis',
        },
        legend: {data: ['当年', '去年']},
        xAxis: {type: 'category', data: Array.from({length: 12}, (_, i) => `${i + 1}月`)},
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: value => `${value} ${this.unit}`
          }
        },
        series: [
          {
            name: '当年',
            type: 'bar',
            smooth: true,
            data: [],
          },
          {
            name: '去年',
            type: 'bar',
            smooth: true,
            data: [],
          },
        ],
      },
      Ranking: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {type: 'shadow'},
          formatter: '{b0}: {c0} ' + this.unit
        },
        legend: {},
        grid: {left: '3%', right: '4%', bottom: '3%', containLabel: true},
        xAxis: {type: 'value', boundaryGap: [0, 0.01]},
        yAxis: {type: 'category', data: []},
        series: [{type: 'bar', data: []}]
      }
    };
  },
  computed: {
    isComparisonUpward() {
      // 判断是否是上升趋势
      return this.isUpward(this.comparisonYesterdayText);
    }
  },
  methods: {
    getGradientColor(value, min, max) {
      var ratio = (value - min) / (max - min);
      var r = Math.round(92 * ratio + 64 * (1 - ratio));
      var g = Math.round(123 * ratio + 158 * (1 - ratio));
      var b = Math.round(217 * ratio + 255 * (1 - ratio));
      return `rgb(${r},${g},${b})`;
    },
    setAutoRefresh() {
      // 设置自动刷新
      this.autoRefreshInterval = setInterval(() => {
        // 获取总能耗数据
        this.fetchTotalEnergyConsumption();
        // 获取今日能耗数据
        this.fetchTodayEnergyConsumption();
        // 获取排名数据
        this.fetchRankingData();
      }, Config.autoRefreshInterval); // 每5分钟刷新一次
    },
    clearAutoRefresh() {
      // 清除自动刷新间隔
      clearInterval(this.autoRefreshInterval);
    },
    // 切换平均值
    toggleMarkPoints() {
      this.markPointsVisible = !this.markPointsVisible;
      this.dailyChartOption.series.forEach(series => {
        series.markPoint = this.markPointsVisible ? {
          data: [
            {type: 'min', name: '最小值'},
            {type: 'max', name: '最大值'},
            {type: 'average', name: '平均值'}
          ],
          label: {
            show: true,
            formatter: '{b}: {c}'
          }
        } : null;
      });
      this.markPointButtonType = this.markPointsVisible ? 'primary' : '';
      if (this.chartRef && this.chartRef.value) {
        this.chartRef.value.setOption(this.dailyChartOption, true);
      }
    },
    async fetchRankingData(params) {
      let apiParams = {
        energyType: params.toggleButtonTypes === 'electricity' ? '电' : '水',
        timeType: '日'
      };

      if (params.timeFrame === 'day') {
        const startTime = params.dateRange[0] ? new Date(params.dateRange[0]).toISOString().split('T')[0] + ' 00:00:00' : new Date().toISOString().split('T')[0] + ' 00:00:00';

// 获取结束时间，如果未提供则使用当前时间
        const endTime = params.dateRange[1] ? new Date(params.dateRange[1]).toISOString().split('T')[0] + ' 23:59:59' : new Date().toISOString().split('T')[0] + ' 23:59:59';

// 设置到 apiParams 对象中
        apiParams.startTime = startTime;
        apiParams.endTime = endTime;
      } else if (params.timeFrame === 'month') {
        apiParams.monthTime = params.month ? params.month : null; // 已格式化为 YYYY-MM
        apiParams.timeType = '月';
      } else if (params.timeFrame === 'year') {
        apiParams.yearTime = params.year ? params.year : null; // 已格式化为 YYYY
        apiParams.timeType = '年';
      }
      console.log(params.QueryTypeFrame)
      if (params.QueryTypeFrame === 'equipment') {
        apiParams.type = 'equipment';
      } else {
        apiParams.type = 'location';
      }


      try {
        if (apiParams.timeType === '日' && (!apiParams.startTime || !apiParams.endTime)){
          return;
        }
        const response = await axios.get(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.getEnergyRanking}`, {params: apiParams});
        const data = response.data;
        if (data.code === 0) {
          nextTick(() => {
            const rankingData = data.data || []; // 确保数据存在
            this.Ranking = {
              tooltip: {
                trigger: 'axis',
                axisPointer: {type: 'shadow'},
                formatter: '{b0}: {c0} ' + this.unit
              },
              legend: {},
              grid: {left: '3%', right: '4%', bottom: '3%', containLabel: true},
              xAxis: {type: 'value', boundaryGap: [0, 0.01]},
              yAxis: {type: 'category', data: rankingData.map(item => item.customRegionName)},
              series: [{
                type: 'bar', data: rankingData.map(item => item.value), itemStyle: {
                  color: (params) => {
                    var value = params.value;
                    var maxValue = 100; // 根据实际数据调整
                    var minValue = 10;   // 根据实际数据调整
                    return this.getGradientColor(value, minValue, maxValue);
                  }
                }
              }]
            };
            if (this.chartRefc) {
              this.chartRefc.setOption(this.Ranking);
            }
          });
        } else {
          console.error("Error:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    buildParams() {
      // 构建请求参数,时间颗粒度，日，月，年
      let params = {};
      const [startDate, endDate] = this.dateRange;
      if (this.timeFrame === 'day') {
        const currentDateString = new Date().toISOString().split('T')[0];
        params = {
          startTime: startDate ? new Date(startDate).toISOString().split('T')[0] + ' 00:00:00' : currentDateString + ' 00:00:00',
          endTime: endDate ? new Date(endDate).toISOString().split('T')[0] + ' 23:59:59' : currentDateString + ' 23:59:59',
          timeType: '日',
          energyType: this.toggleButtonTypes === 'electricity' ? '电' : '水'
        };
      } else if (this.timeFrame === 'month') {
        params = {
          monthTime: this.formatDate(this.month, 'YYYY-MM'),
          timeType: '月',
          energyType: this.toggleButtonTypes === 'electricity' ? '电' : '水'
        };
      } else if (this.timeFrame === 'year') {
        params = {
          yearTime: this.year.getFullYear().toString(),
          timeType: '年',
          energyType: this.toggleButtonTypes === 'electricity' ? '电' : '水'
        };
      }
      return params;
    },
    formatDate(date, format) {
      // 格式化日期
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const monthStr = month < 10 ? '0' + month : month.toString();
      return format.replace('YYYY', year).replace('MM', monthStr);
    },
    isUpward(value) {
      return parseFloat(value) > 0;
    },
    async fetchTotalEnergyConsumption() {
      // 获取总能耗数据
      let params = {energyType: this.toggleButtonTypes === 'electricity' ? '电' : '水'};
      try {
        const response = await axios.get(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.totalEnergyConsumption}`, {params});
        if (response.data.code === 0) {
          this.totalEnergyConsumption = response.data.data;
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('API request error:', error);
      }
    },
    async fetchTodayEnergyConsumption() {
      let params = {energyType: this.toggleButtonTypes === 'electricity' ? '电' : '水'};
      const url = `${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.TodayEnergyConsumption}`;
      try {
        const response = await axios.get(url, {params});
        const data = response.data;
        if (data.code === 0) {
          const energyData = data.data || {}; // 确保数据存在
          const {
            thanDay = 0, toDay = 0, day = 0,
            thanWeek = 0, toWeek = 0, week = 0,
            thanMonth = 0, toMonth = 0, month = 0,
            thanYear = 0, toYear = 0, year = 0,
          } = energyData;

          this.comparisonYesterdayText = thanDay;
          this.todayUsage = day;
          this.yesterdayUsage = toDay;
          this.comparisonWeekText = thanWeek;
          this.WeekUsage = week;
          this.LastWeekUsage = toWeek;
          this.comparisonMonthText = thanMonth;
          this.MonthUsage = month;
          this.MonthsUsage = toMonth;
          this.comparisonYearText = thanYear;
          this.YearUsage = year;
          this.LastYearUsage = toYear;
          this.loading = false;
        } else {
          console.error(`Error fetching data: ${data.message}`);
        }
      } catch (error) {
        console.error('API request error:', error);
      }
    },
    async fetchDataAndUpdateChart() {
      // 获取并更新每日能耗数据
      let params = {energyType: this.toggleButtonTypes === 'electricity' ? '电' : '水'};
      const url = `${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.getEnergyConsumptionDay}`;
      try {
        const response = await axios.get(url, {params});
        const data = response.data;
        if (data.code === 0) {
          const energyData = data.data;
          const todayData = Array(24).fill(0);
          const yesterdayData = Array(24).fill(0);

          energyData.forEach(item => {
            const hour = parseInt(item.time);
            if (item.type === "今日") {
              todayData[hour] = item.value;
            } else if (item.type === "昨日") {
              yesterdayData[hour] = item.value;
            }
          });

          this.dailyChartOption.series[0].data = yesterdayData;
          this.dailyChartOption.series[1].data = todayData;
        }
      } catch (error) {
        console.error('API request error:', error);
      }
    },
    async fetchDataAndUpdateChart_month() {
      // 获取并更新每月能耗数据
      let params = {energyType: this.toggleButtonTypes === 'electricity' ? '电' : '水'};
      const url = `${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.getEnergyConsumptionMonth}`;
      try {
        const response = await axios.get(url, {params});
        const data = response.data;
        if (data.code === 0) {
          const energyData = data.data;
          const currentMonthData = Array(31).fill(0);
          const lastMonthData = Array(31).fill(0);

          energyData.forEach(item => {
            const day = parseInt(item.time);
            if (item.type === "当月") {
              currentMonthData[day - 1] = item.value;
            } else if (item.type === "上月") {
              lastMonthData[day - 1] = item.value;
            }
          });

          this.monthlyChartOption.series[0].data = currentMonthData;
          this.monthlyChartOption.series[1].data = lastMonthData;
        }
      } catch (error) {
        console.error('API request error:', error);
      }
    },
    async fetchDataAndUpdateChart_year() {
      // 获取并更新每年能耗数据
      let params = {energyType: this.toggleButtonTypes === 'electricity' ? '电' : '水'};
      const url = `${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.getEnergyConsumptionYear}`;
      try {
        const response = await axios.get(url, {params});
        const data = response.data;
        if (data.code === 0) {
          const energyData = data.data;
          const currentYearData = Array(12).fill(0);
          const lastYearData = Array(12).fill(0);

          energyData.forEach(item => {
            const month = parseInt(item.time);
            if (item.type === "当年") {
              currentYearData[month - 1] = item.value;
            } else if (item.type === "去年") {
              lastYearData[month - 1] = item.value;
            }
          });

          this.yearChartOption.series[0].data = currentYearData;
          this.yearChartOption.series[1].data = lastYearData;
        }
      } catch (error) {
        console.error('API request error:', error);
      }
    },
    toggleButtonType(button) {
      this.electricButtonType = button === 'electric' ? 'primary' : '';
      this.waterButtonType = button === 'water' ? 'primary' : '';
      this.toggleButtonTypes = button === 'electric' ? 'electricity' : 'water';
      this.updateContent(button);
    },
    updateContent(button) {
      const unit = button === 'electric' ? 'kWh' : 'm³';
      const dayTitle = button === 'electric' ? '当日逐时用电量' : '当日逐时用水量';
      const monthTitle = button === 'electric' ? '当月逐日用电量' : '当月逐日用水量';
      const yearTitle = button === 'electric' ? '当年逐月用电量' : '当年逐月用水量';

      this.unit = unit;
      this.chartTitles.daily = dayTitle;
      this.chartTitles.monthly = monthTitle;
      this.chartTitles.yearly = yearTitle;

      // 清除之前的数据
      this.clearData();

      // 切换后重新查询数据
      this.fetchTotalEnergyConsumption();
      this.fetchTodayEnergyConsumption();
      this.fetchRankingData({
        timeFrame: this.timeFrame,
        dateRange: this.dateRange,
        month: this.month,
        year: this.year,
        toggleButtonTypes: this.toggleButtonTypes
      });
      this.fetchDataAndUpdateChart();
      this.fetchDataAndUpdateChart_month();
      this.fetchDataAndUpdateChart_year();
    },
    clearData() {
      this.totalEnergyConsumption = 0;
      this.todayUsage = 0;
      this.yesterdayUsage = 0;
      this.WeekUsage = 0;
      this.LastWeekUsage = 0;
      this.MonthUsage = 0;
      this.MonthsUsage = 0;
      this.YearUsage = 0;
      this.LastYearUsage = 0;
      this.comparisonYesterdayText = 0;
      this.comparisonWeekText = 0;
      this.comparisonMonthText = 0;
      this.comparisonYearText = 0;
      this.Ranking = {
        tooltip: {},
        legend: {},
        grid: {},
        xAxis: {type: 'value', boundaryGap: [0, 0.01]},
        yAxis: {type: 'category', data: []},
        series: [{type: 'bar', data: []}]
      };
    },
    watchDataChanges() {
      watch(() => this.dateRange, this.fetchRankingData);
      watch(() => this.timeFrame, this.fetchRankingData);
    }
  },
  mounted() {
    const today = new Date();
    this.localDateRange = [today, today];
    this.fetchTotalEnergyConsumption();
    this.fetchTodayEnergyConsumption();
    this.fetchRankingData({
      timeFrame: this.localTimeFrame,
      dateRange: this.localDateRange,
      month: this.localMonth,
      year: this.localYear,
      toggleButtonTypes: this.localToggleButtonTypes
    });
    this.fetchDataAndUpdateChart();
    this.fetchDataAndUpdateChart_month();
    this.fetchDataAndUpdateChart_year();
    this.setAutoRefresh();
    this.watchDataChanges();
  },
  setup() {
    const chartRef = ref(null);
    const chartRefa = ref(null);
    const chartRefb = ref(null);
    const chartRefc = ref(null);

    const resizeChart = () => {
      // 调整图表大小
      if (chartRef.value?.resize) chartRef.value.resize();
      if (chartRefa.value?.resize) chartRefa.value.resize();
      if (chartRefb.value?.resize) chartRefb.value.resize();
    };

    onMounted(() => {
      window.addEventListener('resize', resizeChart); // 监听窗口大小变化
    });

    return {
      chartRef,
      chartRefa,
      chartRefb,
      chartRefc,
    };
  },
  beforeUnmount() {
    this.clearAutoRefresh(); // 在组件销毁前清除定时器
  }
};
</script>

<style scoped>
.app-container {
  padding: 20px;
}

.usage-card {
  background-color: #ffffff;
  color: rgb(114, 109, 109);
  padding: 20px;
  border-radius: 10px;
  position: relative;
  text-align: left;
}

.usage-card .percentage {
  position: absolute;
  top: 15px;
  right: 10px;
  color: #37b837;
}

.main-usage div {
  padding-bottom: 10px;
}

.usage-card .main-usage .value {
  font-size: 30px;
  margin: 10px 0;
}

.usage-card .main-usage .unit {
  font-size: 20px;
}

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controls {
  display: flex;
  align-items: center;
}

.date-picker {
  margin-left: 10px;
}

.last-day-usage,
.last-week-usage,
.last-month-usage,
.last-year-usage {
  font-size: 13px;
}
</style>
        