<template>
  <div class="common-layout">
    <el-card shadow="always">
      <div class="query-container">
        <el-tree-select
          v-model="selectedTreeValue"
          :data="treeData"
          :render-after-expand="false"
          style="width: 240px"
          placeholder="请选择节点"
        />
        <el-select
          v-model="selectedOption"
          placeholder="请选择"
          style="width: 120px"
        >
          <el-option label="日" value="date" />
          <el-option label="月" value="month" />
          <el-option label="年" value="year" />
        </el-select>

        <el-date-picker
          v-model="selectedDate"
          :type="selectedOption"
          placeholder="请选择"
          style="margin-right: 10px"
        />
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </div>
    </el-card>
    <el-card shadow="always">
      <el-row>
        <el-col :span="8">
          <div class="container">
            <div class="box">
              <span class="left">1</span>
              <span class="right">变压器台数</span>
            </div>
            <div class="box">
              <span class="left">1</span>
              <span class="right">额定容量(kVA)</span>
            </div>
            <div class="box">
              <span class="left">1</span>
              <span class="right">容量电费(元)</span>
            </div>
            <div class="box">
              <span class="left">1</span>
              <span class="right">负荷率</span>
            </div>
            <div class="box">
              <span class="left">1</span>
              <span class="right">最大需量</span>
            </div>
            <div class="box">
              <span class="left">1</span>
              <span class="right">需量电费(元)</span>
            </div>
          </div>
        </el-col>
        <el-col :span="16">
          <div ref="chartContainer" class="chart-container"></div>
        </el-col>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row>
        <el-col :span="16">
          <div ref="electricityChartContainer" class="chart-container"></div
        ></el-col>
        <el-col :span="8">
          <div ref="doughnutChartContainer" class="chart-container"></div>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <el-table
        :data="tableData"
        border
        show-summary
        style="width: 100%"
        :header-cell-style="{ backgroundColor: '#ecf5ff' }"
      >
        <el-table-column prop="id" label="电能类型" align="center">
        </el-table-column>
        <el-table-column prop="name" label="单价" align="center">
        </el-table-column>
        <el-table-column prop="amount1" label="电量(kWh)" align="center">
        </el-table-column>
        <el-table-column prop="amount2" label="成本(元/kWh)" align="center">
        </el-table-column>
        <el-table-column prop="amount3" label="容量电费(元/kWh)" align="center">
        </el-table-column>
        <el-table-column prop="amount3" label="合计" align="center">
        </el-table-column>
        <el-table-column prop="amount3" label="同比" align="center">
        </el-table-column>
        <el-table-column prop="amount3" label="环比" align="center">
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
      
  <script setup>
import { ref, onMounted, watch } from "vue";
import * as echarts from "echarts";

// 定义数据和状态
const treeData = ref([
  { value: 1, label: "节点1", children: [{ value: 2, label: "节点1-1" }] },
  { value: 3, label: "节点2" },
]);

const selectedTreeValue = ref(null);
const selectedOption = ref("date"); // 默认选项为 '日'
const selectedDate = ref(null);
const selectedMonth = ref(null);
const selectedYear = ref(null);

// 查询按钮的处理函数
const handleSearch = () => {
  console.log("树选择的值:", selectedTreeValue.value);
  console.log("选择的类型:", selectedOption.value);
  console.log("选择的日期:", selectedDate.value);
  console.log("选择的月份:", selectedMonth.value);
  console.log("选择的年份:", selectedYear.value);
};

// 挂载后初始化图表
const chartContainer = ref(null); //曲线图
const electricityChartContainer = ref(null); //柱状图
const doughnutChartContainer = ref(null); //环形图

const updateChart = () => {
  const chart = echarts.init(chartContainer.value); // 初始化图表
  let xAxisData = [];
  let seriesData = [];

  if (selectedOption.value === "date") {
    xAxisData = Array.from({ length: 24 }, (_, i) => `${i + 1}`);
    seriesData = [100, 222, 120, 230, 290, 330, 120, 230, 290, 330, 120, 230];
  } else if (selectedOption.value === "month") {
    const year = new Date().getFullYear(); // 获取当前年份
    const month = selectedMonth.value || new Date().getMonth() + 1; // 获取选择的月份，或当前月份

    const daysInMonth = new Date(year, month, 0).getDate(); // 获取当前月份的天数
    xAxisData = Array.from({ length: daysInMonth }, (_, i) => `${i + 1}号`);
    seriesData = Array.from({ length: daysInMonth }, () => Math.floor(Math.random() * 300 + 100)); // 生成随机数据示例
  } else if (selectedOption.value === "year") {
    xAxisData = Array.from({ length: 12 }, (_, i) => `${i + 1}月`);
    seriesData = [1000, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200];
  }

  const option = {
    tooltip: {
    trigger: 'axis', // 触发类型为坐标轴触发
    axisPointer: {
      type: 'line', // 指示器类型为线
      lineStyle: {
        color: '#999',
        width: 1,
        type: 'dashed'
      }
    },
    formatter: (params) => {
      // 自定义格式化函数，用于展示 tooltip 内容
      const { name, data } = params[0];
      return `<div><strong>${name}</strong></div><div>电费: ${data}</div>`;
    }
  },
    legend: {
      data: ["需要电费"],
      top: "5%",
      left: "center",
    },
    grid: {
      top: "18%",
      bottom: "10%",
      left: "10%",
      right: "10%",
    },
    xAxis: {
      type: "category",
      data: xAxisData,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "需要电费",
        data: seriesData,
        type: "line",
        smooth: true,
        itemStyle: {
          color: "#A0C4FF", // 设置折线图的颜色为淡蓝色
        },
      },
    ],
  };

  chart.setOption(option);
};

// 更新电量图表
const updateElectricityChart = () => {
  const electricityChart = echarts.init(electricityChartContainer.value);
  let xAxisData = [];
  let series = [];

  if (selectedOption.value === "date") {
    xAxisData = Array.from({ length: 24 }, (_, i) => `${i}:00`);
    series = [
      {
        name: "尖",
        type: "bar",
        stack: "总计",
        data: new Array(24).fill(120).map((v, i) => v + i * 10),
      },
      {
        name: "峰",
        type: "bar",
        stack: "总计",
        data: new Array(24).fill(220).map((v, i) => v + i * 5),
      },
      {
        name: "平",
        type: "bar",
        stack: "总计",
        data: new Array(24).fill(150).map((v, i) => v + i * 7),
      },
      {
        name: "谷",
        type: "bar",
        stack: "总计",
        data: new Array(24).fill(320).map((v, i) => v + i * 3),
      },
      {
        name: "总计",
        type: "line",
        data: new Array(24).fill(810).map((v, i) => v + i * 25),
      },
    ];
  } else if (selectedOption.value === "month") {
    xAxisData = Array.from({ length: 31 }, (_, i) => `${i + 1}号`);
    series = [
      { name: "尖", type: "bar", stack: "总计", data: new Array(31).fill(120) },
      { name: "峰", type: "bar", stack: "总计", data: new Array(31).fill(220) },
      { name: "平", type: "bar", stack: "总计", data: new Array(31).fill(150) },
      { name: "谷", type: "bar", stack: "总计", data: new Array(31).fill(320) },
      { name: "总计", type: "line", data: new Array(31).fill(810) },
    ];
  } else if (selectedOption.value === "year") {
    xAxisData = Array.from({ length: 12 }, (_, i) => `${i + 1}月`);
    series = [
      { name: "尖", type: "bar", stack: "总计", data: new Array(12).fill(120) },
      { name: "峰", type: "bar", stack: "总计", data: new Array(12).fill(220) },
      { name: "平", type: "bar", stack: "总计", data: new Array(12).fill(150) },
      { name: "谷", type: "bar", stack: "总计", data: new Array(12).fill(320) },
      { name: "总计", type: "line", data: new Array(12).fill(810) },
    ];
  }

  const electricityOption = {
    tooltip: {
      trigger: 'axis', // 触发类型为坐标轴触发
      axisPointer: {
        type: 'line', // 指示器类型为线
        lineStyle: {
          color: '#999',
          width: 1,
          type: 'dashed'
        }
      },
      formatter: (params) => {
        return params
          .map(param => `<div><strong>${param.seriesName}</strong>: ${param.data} kWh</div>`)
          .join('');
      }
    },
    color: ["#2fc9cb", "#b6a2de", "#5ab1ef", "#91cc75", "#e7a900"],
    legend: {
      data: series.map((item) => item.name),
      top: "5%",
      left: "center",
    },
    grid: {
      top: "18%",
      bottom: "10%",
      left: "10%",
      right: "10%",
    },
    xAxis: { type: "category", data: xAxisData },
    yAxis: { type: "value", name: "用电量 (kWh)" },
    series,
  };

  electricityChart.setOption(electricityOption);
};
watch(
  () => selectedOption.value,
  (newOption) => {
    const now = new Date();
    if (newOption === 'date') {
      selectedDate.value = now; // 默认选中当前日期
    } else if (newOption === 'month') {
      selectedDate.value = new Date(now.getFullYear(), now.getMonth()); // 默认选中当前月
    } else if (newOption === 'year') {
      selectedDate.value = new Date(now.getFullYear(), 0); // 默认选中今年
    }
    updateChart();
    updateElectricityChart();
  }
);
onMounted(() => {
  updateChart();
  updateElectricityChart();
  // 环形图
  const doughnutChart = echarts.init(doughnutChartContainer.value);
  const colorList = [
    "#66c5d7",
    "#11c88c",
    "#989cff",
    "#ffa55d",
    "#9c7de1",
    "#5d9eff",
    "#ffdb5d",
    "#ee82ed",
    "#8fca5f",
    "#b995f5",
  ];
  const originalData = [
    { value: 55, name: "尖" },
    { value: 70, name: "峰" },
    { value: 25, name: "平" },
    { value: 45, name: "谷" },
  ];

  echarts.util.each(originalData, function (item, index) {
    item.itemStyle = {
      normal: {
        color: colorList[index],
      },
    };
  });

  const doughnutOption = {
    tooltip: {
      trigger: "item",
      formatter: "{b}: {c} ({d}%)",
    },
    backgroundColor: "#ffffff",
    legend: {
      orient: "horizontal", // 水平排列
      top: "-3%", // 图例放置在顶部
      data: originalData.map((item) => item.name), // 图例名称
    },
    series: [
      {
        hoverAnimation: false, // 设置饼图默认的展开样式
        radius: [30, 70],
        name: "pie",
        type: "pie",
        selectedMode: "single",
        selectedOffset: 16, // 选中时扇区偏移量
        clockwise: true,
        startAngle: 90,
        label: {
          normal: {
            show: true, // 显示数据标签
            position: "outside", // 数据标签在饼图外部
            formatter: "{b}: {c} ({d}%)", // 标签格式
          },
        },
        labelLine: {
          normal: {
            show: true, // 显示标签引导线
          },
        },
        itemStyle: {
          normal: {
            borderWidth: 3,
            borderColor: "#ffffff",
          },
          emphasis: {
            borderWidth: 0,
            shadowBlur: 5,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.2)",
          },
        },
        data: originalData,
      },
    ],
  };
  doughnutChart.setOption(doughnutOption, true);
});
const tableData = [
  {
    id: "尖",
    name: "0",
    amount1: "234",
    amount2: "3.2",
    amount3: 10,
  },
  {
    id: "峰",
    name: "0",
    amount1: "165",
    amount2: "4.43",
    amount3: 12,
  },
  {
    id: "平",
    name: "0",
    amount1: "324",
    amount2: "1.9",
    amount3: 9,
  },
  {
    id: "谷",
    name: "0",
    amount1: "621",
    amount2: "2.2",
    amount3: 17,
  },
];
</script>
      
  <style scoped>
.query-container {
  display: flex;
  gap: 10px;
  align-items: center;
}
.el-card {
  margin-bottom: 10px;
}
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.box {
  width: 32%;
  height: 85px;
  position: relative;
  margin-bottom: 10px;
  background: #ecf5ff;
}
.left {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #79bbff;
}
.right {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 14px;
  font-weight: bold;
}
.chart-container {
  width: 100%;
  height: 180px;
}
</style>
  