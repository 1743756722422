<template>
    <div class="usage-card">
      <div class="percentage" :style="{ color: percentageColor ? '#F56C6C' : '#37b837' }">
        {{ percentage }}%
        <el-tooltip content="环比" placement="top">
          <el-icon :color="percentageColor ? '#F56C6C' : '#37b837'">
            <component :is="percentageColor ? 'CaretTop' : 'CaretBottom'" />
          </el-icon>
        </el-tooltip>
      </div>
      <div class="main-usage">
        <div>{{ mainUsageTitle }}</div>
        <div>
          <span class="value">{{ mainUsageValue }}</span>
          <span class="unit">{{ unit }}</span>
        </div>
      </div>
      <div class="divider"></div>
      <div class="last-day-usage">
        <div>{{ previousUsageText }} {{ lastDayUsage }} {{ unit }}</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'UsageCard',
    props: {
      percentage: {
        type: Number,
        required: true
      },
      percentageColor: {
        type: Boolean,
        required: true
      },
      mainUsageTitle: {
        type: String,
        required: true
      },
      mainUsageValue: {
        type: Number,
        required: true
      },
      lastDayUsage: {
        type: Number,
        required: true
      },
      unit: {
        type: String,
        required: true
      },
      toggleButtonTypes: {
        type: String,
        required: true
      }
    },
    computed: {
      previousUsageText() {
        switch (this.mainUsageTitle) {
          case '今日用量':
            return '昨日用量';
          case '本周用量':
            return '上周用量';
          case '本月用量':
            return '上月用量';
          case '当年用量':
            return '去年用量';
          default:
            return '';
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .usage-card {
    background-color: #ffffff;
    color: rgb(114, 109, 109);
    padding: 20px;
    border-radius: 10px;
    position: relative;
    text-align: left;
  }
  
  .usage-card .percentage {
    position: absolute;
    top: 15px;
    right: 10px;
  }
  
  .main-usage div {
    padding-bottom: 10px;
  }
  
  .usage-card .main-usage .value {
    font-size: 30px;
    margin: 10px 0;
  }
  
  .usage-card .main-usage .unit {
    font-size: 20px;
  }
  
  .divider {
    border-top: 1px solid #FFF;
    margin: 6px 0;
  }
  
  .last-day-usage {
    font-size: 13px;
  }
  </style>
  