<template>
    <el-col :span="12">
      <el-card shadow="never">
        <template #header>
          <div class="card-header">
            <span>用能排行
              <div style="float: right; padding-top: 2px;">
                <img v-if="localToggleButtonTypes === 'electricity'" src="@/assets/svg/electricity.svg" class="icon" width="20" height="20" />
                <img v-else-if="localToggleButtonTypes === 'water'" src="@/assets/svg/water.svg" class="icon" width="16" height="16" />
              </div>
            </span>
            <div class="controls">
              <el-select v-model="QueryType" placeholder="选择查询类型" style="width: 80px;margin-right: 5px" size="small">
                <el-option label="分组" value="location"></el-option>
                <el-option label="设备" value="equipment"></el-option>
              </el-select>
              <el-select v-model="localTimeFrame" placeholder="选择时间类型" filterable allow-create default-first-option style="width: 50px" size="small">
                <el-option label="日" value="day"></el-option>
                <el-option label="月" value="month"></el-option>
                <el-option label="年" value="year"></el-option>
              </el-select>
              <div v-if="localTimeFrame === 'day'" class="date-picker">
                <el-date-picker v-model="localDateRange" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 170px"  size="small"></el-date-picker>
              </div>
              <div v-else-if="localTimeFrame === 'month'" class="date-picker">
                <el-date-picker v-model="localMonth" type="month" placeholder="选择月"  size="small" style="width: 80px"></el-date-picker>
              </div>
              <div v-else-if="localTimeFrame === 'year'" class="date-picker">
                <el-date-picker v-model="localYear" type="year" placeholder="选择年"  size="small" style="width: 80px"></el-date-picker>
              </div>
              <el-button type="primary" style="margin-left: 10px;" @click="handleFetchData" size="small">查询</el-button>
            </div>
          </div>
        </template>
        <div class="chart-container">
          <v-chart :option="rankingOption" ref="chartRef" theme="" />
        </div>
      </el-card>
    </el-col>
  </template>
  
  <script>
  export default {
    name: 'RankingCard',
    props: {
      title: {
        type: String,
        required: true
      },
      timeFrame: {
        type: String,
        required: true
      },
      QueryTypeFrame: {
        type: String,
        required: true
      },
      dateRange: {
        type: Array,
        required: false,
        default: () => []
      },
      month: {
        type: String,
        required: false
      },
      year: {
        type: String,
        required: false
      },
      rankingOption: {
        type: Object,
        required: true
      },
      fetchData: {
        type: Function,
        required: true
      },
      toggleButtonTypes: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        localTimeFrame: this.timeFrame,
        QueryType:'location',
        localDateRange: this.dateRange,
        localMonth: this.month,
        localYear: this.year,
        localToggleButtonTypes: this.toggleButtonTypes
      };
    },
    methods: {
      formatDate(date, format) {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        if (format === 'YYYY-MM') {
          return `${year}-${month}`;
        } else if (format === 'YYYY') {
          return `${year}`;
        } else if (format === 'start') {
          return `${year}-${month}-${day} 00:00:00`;
        } else if (format === 'end') {
          return `${year}-${month}-${day} 23:59:59`;
        } else {
          return `${year}-${month}-${day}`;
        }
      },
      handleFetchData() {
        // 调用父组件传递的fetchData方法并传递参数
        this.fetchData({
          timeFrame: this.localTimeFrame,
          QueryTypeFrame:this.QueryType,
          dateRange: this.localDateRange.length ? [this.formatDate(this.localDateRange[0], 'start'), this.formatDate(this.localDateRange[1], 'end')] : [],
          month: this.localMonth ? this.formatDate(this.localMonth, 'YYYY-MM') : '',
          year: this.localYear ? this.formatDate(this.localYear, 'YYYY') : '',
          toggleButtonTypes: this.localToggleButtonTypes
        });
      },
      clearData() {
        this.localDateRange = [];
        this.localMonth = '';
        this.localYear = '';
      }
    },
    watch: {
      timeFrame(newVal) {
        this.localTimeFrame = newVal;
      },
      QueryTypeFrame(newVal){
        this.QueryType = newVal;
      },
      dateRange(newVal) {
        this.localDateRange = newVal;
      },
      month(newVal) {
        this.localMonth = newVal;
      },
      year(newVal) {
        this.localYear = newVal;
      },
      toggleButtonTypes(newVal) {
        this.localToggleButtonTypes = newVal;
        this.clearData(); // 切换按钮类型时清空数据
        this.handleFetchData(); // 重新获取数据
      }
    },
    mounted() {
  // 设置默认日期范围为当天
  const today = new Date();
  this.localDateRange = [today, today];
  this.handleFetchData();

    }
  };
  </script>
  
  <style scoped>
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .controls {
    display: flex;
    align-items: center;
  }
  
  .date-picker {
    margin-left: 10px;
  }
  
  .chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
  }
  </style>
  