<!-- /*
                   _ooOoo_
                  o8888888o
                  88" . "88
                  (| -_- |)
                  O\  =  /O
               ____/`---'\____
             .'  \\|     |//  `.
            /  \\|||  :  |||//  \
           /  _||||| -:- |||||-  \
           |   | \\\  -  /// |   |
           | \_|  ''\---/''  |   |
           \  .-\__  `-`  ___/-. /
         ___`. .'  /--.--\  `. . __
      ."" '<  `.___\_<|>_/___.'  >'"".
     | | :  `- \`.;`\ _ /`;.`/ - ` : | |
     \  \ `-.   \_ __\ /__ _/   .-` /  /
======`-.____`-.___\_____/___.-`____.-'======
                   `=---='
^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
         佛祖保佑       永无BUG
*/ -->
<template>
  <el-container>
    <el-aside width="200px">
      <el-header>
        <span>分组结构</span>
        <el-dropdown @command="handleCommand">
          <el-button type="text" class="el-icon-more">操作</el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="add">添加</el-dropdown-item>
              <!-- <el-dropdown-item command="edit">编辑</el-dropdown-item>
              <el-dropdown-item command="delete">删除</el-dropdown-item> -->
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-header>
      <el-tree
          ref="departmentTree"
          :data="treeData"
          :props="defaultProps"
          node-key="id"
          highlight-current
          default-expand-all
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
          @check-change="handleCheckChange"
      />
    </el-aside>

    <el-container>
      <el-main>
        <div class="btnlet">
          <!-- <el-button type="primary" @click="showAddDeviceDialog">添加分组设备</el-button> -->

          <!-- 批量导入按钮 -->
          <el-button type="primary" size="small" @click="handleFileClick" style="margin-right: 8px">批量导入</el-button>

          <!-- 隐藏文件上传输入框，用来选择 xlsx 文件 -->
          <input type="file" ref="fileInput" style="display: none" @change="insertBatchXLSX" accept=".xlsx, .xls"/>


          <el-button type="primary" size="small" @click="exportTemplateXLSX">导出模板</el-button>
          <el-button type="primary" size="small" @click="isDeleteDeviceDialogVisible = true">批量删除</el-button>
        </div>
        <el-table :data="filteredDepartments" style="width: 100%">
          <el-table-column prop="id" label="序号" width="90"></el-table-column>
          <el-table-column prop="name" label="分组名称"></el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button size="small" @click="openDialog('edit', scope.$index, scope.row)">编辑</el-button>
              <el-popconfirm title="确定删除记录?" @confirm="confirmDelete(scope.row.id)">
                <template #reference>
                  <el-button size="small" type="danger">删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
    <el-dialog :title="dialogTitle" v-model="dialogVisible" draggable resizable>
      <el-form :model="form">
        <el-form-item label="分组名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="选择分组">
          <el-tree-select
              v-model="form.parentId"
              :data="treeData"
              placeholder="请选择分组"
              check-strictly
              default-expand-all
              value-key="id"
              label-key="name"
              :props="{ label: 'name', children: 'children' }"
          />
        </el-form-item>
        <el-form-item label="排序序号">
          <el-input-number
              v-model="form.sort"
              :precision="0"
              :step="1"
              min="1"
              size="small"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveDepartment">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <!--批量删除-->
    <el-dialog title="批量删除" v-model="isDeleteDeviceDialogVisible" style="width: 60%">
      <el-transfer
          v-model:modelValue="deleteEquipmentIds"
          :data="deleteEquipmentData"
          :titles="['分组列表', '已选分组']"
          :filter-method="filterEquipment"
          filterable
          filter-placeholder="请输入分组名称(多条件用中文逗号隔开)"
          class="equipment-transfer"
      />
      <template #footer>
        <el-button @click="isDeleteDeviceDialogVisible=false">取消</el-button>
        <el-button @click="deleteEquipmentIdsClick">确定</el-button>
      </template>
    </el-dialog>
  </el-container>
</template>

<script>
import axios from 'axios';
import {ref, onMounted} from 'vue';
import {ElMessage} from 'element-plus';
import ApiConfig from '@/APIConfig.js';
import * as XLSX from "xlsx";

export default {
  name: 'DepartmentManagement',
  setup() {
    const treeData = ref([]);
    const flatTreeData = ref([]);

    const filteredDepartments = ref([]);
    const dialogVisible = ref(false);
    const dialogTitle = ref('');
    const form = ref({
      parentId: null,
      name: '',
      sort: 1
    });
    const editingIndex = ref(-1);
    const selectedNode = ref(null);
    const loading = ref(false);
    const nextId = ref(25);
    const departmentTree = ref(null); // 添加 departmentTree 以避免未定义错误
    const defaultProps = { // 添加 defaultProps 以避免未定义错误
      children: 'children',
      label: 'name',
    };
    const fileInput = ref(null);
    const handleFileClick = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };
    const isDeleteDeviceDialogVisible = ref(false)
    const deleteEquipmentIds = ref()
    const filterEquipment = (query, item) => {
      let conditions = query.split('，');
      // 使用 every 来检查 item.label 是否包含所有条件
      return conditions.every(condition => item.label.includes(condition));
    }
    const deleteEquipmentData = ref()
    //批量删除
    const deleteEquipmentIdsClick = () => {
      deleteEquipmentIds.value.forEach((item) => {
        deleteDepartment(item)
      })
    }
    //导入分组
    const insertBatchXLSX = async (event) => {
      const file = event.target.files[0];
      if (!file) return;
      try {
        // 创建 FormData 对象并附加文件
        const formData = new FormData();
        formData.append('file', file);  // 'file' 是后端接收文件的字段名

        loading.value = true;

        // 发送 POST 请求上传文件
        const response = await axios.post(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.insertLocationBatch}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',  // 设置为文件上传
          },
        });
        ElMessage.success(response.data.message)
      } catch (error) {
        ElMessage.error('导入区域数据失败');
        console.error(error);
      } finally {
        await fetchTreeData()
      }
    };
    //导出模板
    const exportTemplateXLSX = () => {
      // 创建表格数据
      const ws_data = [
        ['名称'],  // 第一行
        ['1号楼/1F/入户'],  // 第二行
      ];

      // 创建工作簿
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(ws_data);  // 使用二维数组创建表格

      // 将工作表添加到工作簿
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // 导出文件
      XLSX.writeFile(wb, '分组模板.xlsx');
    }

    const fetchTreeData = async () => {
      try {
        loading.value = true;
        const response = await axios.get(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.getAlllocation}`);
        treeData.value = convertToTree(response.data.data);
        flatTreeData.value = flattenTree(response.data.data);

        deleteEquipmentData.value = response.data.data.map((item) => ({
          key: item.id,
          label: item.name,
        }));
      } catch (error) {
        ElMessage.error('获取区域数据失败');
        console.error(error);
      } finally {
        loading.value = false;
      }
    };

    const convertToTree = (data) => {
      const idMap = data.reduce((acc, item) => {
        acc[item.id] = {...item, children: []};
        return acc;
      }, {});

      const tree = [];
      data.forEach((item) => {
        if (item.parent_id === null) {
          tree.push(idMap[item.id]);
        } else {
          idMap[item.parent_id].children.push(idMap[item.id]);
        }
      });
      return tree;
    };

    const flattenTree = (data) => {
      const result = [];
      const flatten = (node) => {
        result.push({id: node.id, name: node.name});
        if (node.children && node.children.length) {
          node.children.forEach(flatten);
        }
      };
      data.forEach(flatten);
      return result;
    };

    const handleNodeClick = async (data, node) => {
      selectedNode.value = node;
      console.log('Selected node:', data);
      fetchDepartmentsByParentId(data.id);
    };

    const fetchDepartmentsByParentId = async (parentId) => {
      console.log('Fetching departments for parentId:', parentId);
      try {
        const response = await axios.get(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.getDepartmentsByParentId}?parentId=${parentId}`);
        filteredDepartments.value = response.data.data;
      } catch (error) {
        ElMessage.error('获取分组数据失败');
        console.error(error);
      }
    };

    const handleCheckChange = () => {
      const checkedNodes = departmentTree.value.getCheckedNodes();
      selectedNode.value = checkedNodes.length ? checkedNodes[0] : null;
    };

    const handleCommand = (command) => {
      if (command === 'add') {
        if (selectedNode.value) {
          form.value.parentId = selectedNode.value.data.id;
        } else {
          form.value.parentId = null;
        }
        openDialog('add');
      } else if (command === 'edit') {
        if (selectedNode.value) {
          openDialog('edit', null, selectedNode.value.data);
        } else {
          ElMessage.warning('请先选择一个节点');
        }
      } else if (command === 'delete') {
        if (selectedNode.value) {
          deleteDepartment(selectedNode.value.data.id);
        } else {
          ElMessage.warning('请先选择一个节点');
        }
      }
    };

    const openDialog = (type, index = -1, row = {}) => {
      if (type === 'add') {
        dialogTitle.value = '新增分组';
        form.value = {
          parentId: selectedNode.value ? selectedNode.value.data.id : null,
          name: '',
          sort: 1
        };
        editingIndex.value = -1;
      } else if (type === 'edit') {
        console.log(row)
        dialogTitle.value = '编辑分组';
        form.value = {
          id: row.id,
          parentId: row.parent_id,
          name: row.name,
          sort: row.sort
        };
        editingIndex.value = index;
      }
      dialogVisible.value = true;
    };

    const saveDepartment = async () => {
      if (editingIndex.value === -1) {
        try {
          const response = await axios.post(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.newDepartment}`, {
            name: form.value.name,
            parent_id: form.value.parentId,
            sort: form.value.sort,
          });

          if (response.data && response.data.code === 0 && response.data.data === 1) {
            const newDepartment = {
              id: nextId.value++,
              parent_id: form.value.parentId,
              name: form.value.name,
              children: [],
            };

            if (form.value.parentId) {
              const parentNode = findNodeById(treeData.value, form.value.parentId);
              if (parentNode) {
                parentNode.children.push(newDepartment);
              }
            } else {
              treeData.value.push(newDepartment);
            }

            ElMessage.success('新增成功');
            fetchTreeData();
            if (form.value.parentId) {
              fetchDepartmentsByParentId(form.value.parentId);
            } else {
              fetchTreeData();
            }
          } else {
            ElMessage.error('新增失败');
          }
        } catch (error) {
          ElMessage.error('新增失败');
          console.error(error);
        }
      } else {
        try {
          const response = await axios.put(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.updateDepartment}`, {
            id: form.value.id,
            name: form.value.name,
            parent_id: form.value.parentId,
            sort: form.value.sort,
          });

          if (response.data && response.data.message === "操作成功" && response.data.data === 1) {
            if (selectedNode.value) {
              selectedNode.value.data.name = form.value.name;
              selectedNode.value.data.parentId = form.value.parentId;
            }
            ElMessage.success('编辑成功');
            fetchTreeData();
            const parentId = form.value.parentId ? form.value.parentId : selectedNode.value.data.id;
            fetchDepartmentsByParentId(parentId);
          } else {
            ElMessage.error('编辑失败');
          }
        } catch (error) {
          ElMessage.error('编辑失败');
          console.error(error);
        }
      }
      dialogVisible.value = false;
    };

    const deleteDepartment = async (id) => {
      try {
        const response = await axios.delete(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.deleteDepartment}`, {
          params: {id: id},
        });

        if (response.data && response.data.code === 0 && response.data.data === 1) {
          ElMessage.success('删除成功');
          fetchTreeData();
          filteredDepartments.value = filteredDepartments.value.filter(department => department.id !== id);
        } else {
          ElMessage.error('删除失败');
          console.error('Unexpected response format or values:', response.data);
        }
      } catch (error) {
        ElMessage.error('删除失败');
        console.error(error);
      }
    };

    const confirmDelete = async (id) => {
      await deleteDepartment(id);
    };

    const findNodeById = (nodes, id) => {
      for (const node of nodes) {
        if (node.id === id) {
          return node;
        }
        if (node.children && node.children.length > 0) {
          const found = findNodeById(node.children, id);
          if (found) {
            return found;
          }
        }
      }
      return null;
    };

    onMounted(() => {
      fetchTreeData();
    });

    return {
      departmentTree,
      treeData,
      flatTreeData,
      defaultProps,
      filteredDepartments,
      dialogVisible,
      dialogTitle,
      form,
      selectedNode,
      loading,
      handleNodeClick,
      handleCheckChange,
      handleCommand,
      openDialog,
      saveDepartment,
      deleteDepartment,
      confirmDelete,
      fetchTreeData,
      nextId,
      exportTemplateXLSX,
      insertBatchXLSX,
      handleFileClick,
      fileInput,
      isDeleteDeviceDialogVisible,
      deleteEquipmentIds,
      filterEquipment,
      deleteEquipmentIdsClick,
      deleteEquipmentData,
    };
  },
};
</script>

<style scoped>
.el-icon-more {
  margin-bottom: 20px;
}

.dialog-footer {
  text-align: right;
}

.el-aside {
  background-color: #f9f9f9;
  padding: 10px;
}

.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  --el-header-height: 40px;
}

.el-dropdown {
  margin-top: 20px;
}

.area-select {
  width: 100%;
}
</style>
