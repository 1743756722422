        <!-- /*
                           _ooOoo_
                          o8888888o
                          88" . "88
                          (| -_- |)
                          O\  =  /O
                       ____/`---'\____
                     .'  \\|     |//  `.
                    /  \\|||  :  |||//  \
                   /  _||||| -:- |||||-  \
                   |   | \\\  -  /// |   |
                   | \_|  ''\---/''  |   |
                   \  .-\__  `-`  ___/-. /
                 ___`. .'  /--.--\  `. . __
              ."" '<  `.___\_<|>_/___.'  >'"".
             | | :  `- \`.;`\ _ /`;.`/ - ` : | |
             \  \ `-.   \_ __\ /__ _/   .-` /  /
        ======`-.____`-.___\_____/___.-`____.-'======
                           `=---='
        ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
                 佛祖保佑       永无BUG
        */ -->
<template>
  <div id="app">
    <el-container>
      <el-header v-if="isStandalone">
        <el-menu
          :default-active="$route.path"
          class="el-menu-demo"
          mode="horizontal"
          router
        >
          <el-menu-item index="/overview.html"> 能耗总览 </el-menu-item>
          <el-menu-item index="/EnergyReport.html"> 历史查询 </el-menu-item>
          <el-menu-item index="/EnergyPricingStrategy.html"> 计价策略 </el-menu-item>
          <el-menu-item index="/EnergyUsageReport.html"> 用能集抄 </el-menu-item>
          <el-menu-item index="/ChartAnalysis.html"> 同比分析 </el-menu-item>
          <el-menu-item index="/EnergyComparison.html"> 环比分析 </el-menu-item>
          <el-menu-item index="/department-management.html"> 分组管理 </el-menu-item>
          <el-menu-item index="/DeviceManagement.html"> 设备管理 </el-menu-item>
          <el-menu-item index="/TimeSharing.html"> 分时段用电 </el-menu-item>
          <el-menu-item index="/FixedQuery.html"> 固定策略查询 </el-menu-item>
          <el-menu-item index="/OriginalData.html"> 分时段用电原始数据 </el-menu-item>
        </el-menu>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {computed} from "vue";
import {useRoute} from "vue-router";

export default {
  name: "App",
  setup() {
    const route = useRoute();
    // 判断当前 URL 是否包含 .html
    const isStandalone = computed(() => route.path.includes('.html'));

    return { isStandalone };
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: rgb(240, 242, 245);
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-main {
  padding: 20px;
}
</style>
