        <!-- /*
                           _ooOoo_
                          o8888888o
                          88" . "88
                          (| -_- |)
                          O\  =  /O
                       ____/`---'\____
                     .'  \\|     |//  `.
                    /  \\|||  :  |||//  \
                   /  _||||| -:- |||||-  \
                   |   | \\\  -  /// |   |
                   | \_|  ''\---/''  |   |
                   \  .-\__  `-`  ___/-. /
                 ___`. .'  /--.--\  `. . __
              ."" '<  `.___\_<|>_/___.'  >'"".
             | | :  `- \`.;`\ _ /`;.`/ - ` : | |
             \  \ `-.   \_ __\ /__ _/   .-` /  /
        ======`-.____`-.___\_____/___.-`____.-'======
                           `=---='
        ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
                 佛祖保佑       永无BUG
        */ -->
<template>
    <el-col :span="12" style="margin-bottom: 20px;">
      <el-card shadow="never">
        <template #header>
          <div class="card-header">
            <span class="chart-title">{{ title }}</span>
            <el-button-group>
              <el-button :type="chartType === 'bar' ? 'primary' : ''" @click="toggleChartType('bar')" size="small">柱状</el-button>
              <el-button :type="chartType === 'line' ? 'primary' : ''" @click="toggleChartType('line')" size="small">曲线</el-button>
              <el-button :type="markPointsVisible ? 'primary' : ''" @click="toggleMarkPoints" size="small">平均值</el-button>
            </el-button-group>
          </div>
        </template>
        <div class="chart-container">
          <v-chart :option="chartOption" :ref="chartRef" theme="" />
        </div>
      </el-card>
    </el-col>
  </template>
  
  <script>
  export default {
    name: 'ChartCard',
    props: {
      title: {
        type: String,
        required: true
      },
      chartOption: {
        type: Object,
        required: true
      },
      chartRef: {
        type: Object,
        required: true
      },
      initialMarkPointsVisible: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        chartType: 'bar',
        markPointsVisible: this.initialMarkPointsVisible,
      };
    },
    methods: {
      toggleChartType(type) {
        this.chartType = type;
        this.chartOption.series.forEach(series => {
          series.type = type;
        });
        if (this.chartRef?.value) {
          this.chartRef.value.setOption(this.chartOption, true);
        }
      },
      toggleMarkPoints() {
        this.markPointsVisible = !this.markPointsVisible;
        this.chartOption.series.forEach(series => {
          series.markPoint = this.markPointsVisible ? {
            data: [
              { type: 'min', name: '最小值' },
              { type: 'max', name: '最大值' },
              { type: 'average', name: '平均值' }
            ],
            label: {
              show: true,
              formatter: '{b}: {c}'
            }
          } : null;
        });
        if (this.chartRef?.value) {
          this.chartRef.value.setOption(this.chartOption, true);
        }
      }
    },
    mounted() {
      // 初始化时设置所有 series 类型为 'bar'
      this.chartOption.series.forEach(series => {
        series.type = 'bar';
      });
      if (this.chartRef?.value) {
        this.chartRef.value.setOption(this.chartOption, true);
      }
  
      if (!this.markPointsVisible) {
        this.chartOption.series.forEach(series => {
          series.markPoint = null;
        });
        if (this.chartRef?.value) {
          this.chartRef.value.setOption(this.chartOption, true);
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
  }
  </style>
  